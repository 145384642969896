@import '../../styles/variables';

.observedBadge {
  align-items: center;
  background-color: $background;
  border-radius: 3px;
  border: 1px solid $textPrimary;
  color: $textPrimary;
  display: flex;
  height: auto;
  justify-content: center;
  text-transform: uppercase;

  :global(.MuiChip-label) {
    font-family: $product-font-linear;
    font-size: 8px;
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 1em;
    padding: 0;
    white-space: nowrap;
  }

  &.medium {
    line-height: 14px;
    padding: 4px;
  }

  &.small {
    border-radius: 2px;
    line-height: 12px;
    padding: 2px;

    :global(.MuiChip-label) {
      font-size: 6px;
    }
  }
}
